/* Bubble Status Styles */

.bubble {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 0.4375rem;
    display: inline-block;
    margin-right: 0.625rem;
    margin-bottom: -0.125rem;
    background-color: var(--white);
}

.bubble:before {
    content: '';
    display: block;
    padding-top: 100%;
}

.bubble .circular {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    width: 1.125rem;
    height: 1.125rem;
    position: absolute;
    top: 1.0625rem;
    bottom: 0;
    left: 0.3125rem;
    right: 0;
    z-index: 10;
}

.bubble .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

.New {
    background-color: var(--white);
}

.Failed,
.Error {
    background-color: var(--red);
}

.v7Error {
    background-color: var(--red);
}

.Deleting {
    background-color: var(--offWhite);
}

.Defunct {
    background-color: var(--black);
}

.Updating {
    background-color: none;
}

.Unregistered,
.Registering {
    background-color: var(--offWhite);
}

.Registered,
.Active,
.Online,
.Success,
.Finished {
    background-color: var(--green);
}

.Offline {
    background-color: var(--red);
}

.v7Offline,
.New {
    background-color: var(--offWhite);
}

.v7Online {
    background-color: var(--green);
}

.v7OnlineOffline {
    border-style: solid;
    border-color: var(--offWhite);
    border-width: 0.125rem;

    &:hover {
        border-color: var(--primaryColor);
    }
}

.Warning,
.Unknown {
    background-color: var(--yellow);
}

.IsDeleting {
    text-decoration: line-through;
}

#Networks,
#Tenants {
    border-radius: 0.4375rem;
    height: 0rem;
    width: 101.5%;
    overflow: hidden;
    position: absolute;
    top: -0.125rem;
    left: -0.125rem;
    padding-top: 0.125rem;
    background-color: var(--menu);
    min-height: 1.25rem;
    z-index: 60;
    opacity: 0;
    transition: 0.5s;
    pointer-events: none;
}

#Networks.opened,
#Tenants.opened {
    height: 18.75rem;
    opacity: 1;
    pointer-events: all;
}

#Networks .navItem,
#Tenants .navItem {
    font-size: 0.875rem;
    position: absolute;
    background-color: #363b4c;
    width: 100%;
    height: 2rem;
    line-height: 2rem;
    color: var(--white);
    text-align: center;
    bottom: 0rem;
    font-weight: 600;
    font-family: 'Open Sans';
    transition: 0.5s;
}

#Networks .navItem:hover,
#Tenants .navItem:hover {
    background-color: var(--primaryColor);
    transition: 0.5s;
}

#Networks .navItem:active,
#Tenants .navItem:active {
    filter: brightness(90%);
    transition: 0.5s;
}

#Networks input,
#Tenants input {
    height: 2.5rem;
    font-size: 0.9375rem;
    background-image: url(/assets/svgs/Filter.svg);
    background-size: 1.5rem;
    background-position: right 0.75rem top 0.4375rem;
    background-repeat: no-repeat;
    font-weight: 600;
}

#Networks .currentNetwork,
#Tenants .currentTenant {
    background-color: var(--menu);
    border-color: var(--menu);
    border-width: .1rem;
    border-radius: 0.4375rem;
    border-style: solid;
    line-height: 2.0625rem;
    background-image: url(/assets/svgs/ArrowUp.svg);
    background-position: center right -1px;
    background-size: 1.25rem 0.625rem;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 1.25rem;
    width: 16.1875rem;
}

.networkIcon {
    background-image: url(/assets/images/IconGlobe.png);
    background-color: var(--icon);
    background-size: 1.25rem 1.25rem;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 0.4375rem;
    background-position: center center;
    z-index: 26;
    position: absolute;
    left: -.1rem;
    top: -.1rem;
}

.status {
    width: 2.375rem;
    height: 2.375rem;
    background-color: var(--stroke);
    color: var(--white);
    position: absolute;
    left: 0.375rem;
    top: 0.375rem;
    text-align: center;
    text-transform: uppercase;
    line-height: 2.5rem;
    background-image: url(/assets/svgs/MainMenuNetworks.svg);
    background-position: center center;
    background-size: 70% 70%;
    border-radius: 1.5625rem;
}

.orgStatus {
    width: 1.5625rem;
    height: 1.5625rem;
    background-color: var(--stroke);
    color: var(--white);
    position: absolute;
    left: 0.625rem;
    top: 0.1875rem;
    text-align: center;
    text-transform: uppercase;
    line-height: 2.5rem;
    background-image: url(/assets/svgs/MainMenuOrg.svg);
    background-position: center center;
    background-size: 70% 70%;
    border-radius: 1.5625rem;
}

.bubble:before {
    content: '';
    display: block;
    padding-top: 100%;
}

.s300 {
    fill: var(--white);
}

.s400 {
    fill: var(--green);
}

.sPROVISIONED {
    fill: var(--green);
}

/* V2 API Status   NEW, PROVISIONING, PROVISIONED, ERROR, UPDATING, REPLACING, DELETING, DELETED; */

.s500 {
    fill: var(--red);
}

.sERROR {
    fill: var(--red);
}

.b300 {
    background-color: var(--primaryColor);
}

.purple {
    background-color: var(--primaryColorHover);
}

.yellow,
.severityMinor,
.severityWarning {
    background-color: var(--yellow);
}

.blue {
    background-color: var(--white);
}

.red,
.severityMajor,
.severityError {
    background-color: var(--red);
}

.white,
.b400 {
    background-color: var(--white);
}

.b400 {
    background-color: var(--offWhite);
}

.b300.gateway,
.b300.client {
    background-color: var(--icon);
}

.red.striped,
.b500 {
    background-color: var(--red);
    border-style: solid;
    border-color: var(--redHover);
    border-width: 1px;
}

.green,
.severityInfo,
.b300,
.b400.gateway,
.b400.client {
    background-color: var(--green);
}

.tIcon.hidden {
    display: none;
}

.rotate {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.status.s300,
.status.sPROVISIONED,
.bubble.s300 .bubble.sPROVISIONED {
    background-color: var(--green);
}

.status.s400,
.networkIcon.s400,
.bubble.s400,
.orgStatus.s300 {
    background-color: var(--primaryColor);
}

.status.s400,
.networkIcon.s400 .status.sERROR,
.networkIcon.sERROR {
    background-color: var(--red);
}

.status.s200,
.networkIcon.s200,
.bubble.s200 .status.sBUILDING,
.status.sUPDATING,
.status.REPLACING,
.status.sPROVISIONING {
    background-image: none;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    float: left;
    border-radius: 1.5625rem;
    background-color: var(--inputBorder);
}

.status.s200 .anim5,
.networkIcon.s200 .anim5,
.status.sBUILDING .anim5,
.status.sUPDATING .anim5,
.status.sREPLACING .anim5,
.status.sPROVISIONING .anim5 {
    display: inline;
}

.networkIcon.s100,
.bubble.s100,
.bubble.sBUILDING,
.bubble.sPROVISIONING {
    background-color: var(--icon);
}

.networkIcon.s300,
.networkIcon.sPROVISIONED {
    background-color: var(--primaryColor);
}

.ag-cell-value {
  .network-status-cell {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--gapSmall);
    .status {
      position: relative;
      left: 0;
      flex: 0 0 auto;
    }
    .status .ha-badge {
      line-height: .8rem;
      position: absolute;
      top: 1.5rem;
      left: 1.3rem;
      background-color: var(--haBadgeColor);
      color: var(--white);
      height: 0.8rem;
      border-radius: 0.1875rem;
      overflow: visible;
      display: flex;
      font-family: icomoon;
      &:before {
        content: '\e925';
      }
    }
  }

  .ha-node-container {
    display: flex;
    gap: var(--gapSmall);

    .ha-leader-placeholder {
      height: 1.875rem;
      width: 1.875rem;
      position: relative;
      flex: 0 0 auto;
    }

    .ha-leader {
      background-color: transparent;
      color: var(--primaryColor);
      height: 1.875rem;
      width: 1.875rem;
      border-radius: 0.1875rem;
      overflow: visible;
      display: flex;
      font-size: 1.875rem;
      font-family: icomoon;
      flex: 0 0 auto;
      &:before {
        content: '\e96d';
      }
    }
  }
}

.networkIcon.s800 {
    background-color: var(--icon);
}

.anim5 {
    display: none;
    width: 1.5625rem;
    height: 1.5625rem;
    border-top: 0.25rem solid var(--offWhite);
    border-bottom: 0.25rem solid var(--offWhite);
    border-right: 0.25rem solid var(--icon);
    border-left: 0.25rem solid var(--icon);
    -webkit-animation: anim5 0.75s ease infinite;
    animation: anim5 0.75s ease infinite;
    border-radius: 100%;
}

/* Bubble Status Styles For Endpoint Completion Screen */

.completedEndpointBubble {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.4375rem;
    position: relative;
}

.completedEndpointBubble .circular {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    width: 2.5rem;
    height: 2.5rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    --stroke: var(--white);
}

.completedEndpointBubble .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

.header-nav-icon .status .ha-badge {
  background-color: var(--haBadgeColor);
  color: var(--white);
  height: 0.8rem;
  border-radius: 0.1875rem;
  overflow: visible;
  display: flex;
  font-family: icomoon;
  &:before {
    content: '\e925';
  }
}
