@import '../../../../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import '../../../../../node_modules/jsoneditor/dist/jsoneditor.min.css';

html {
  font-size: 90%;
}

.zac-wrapper-container {
    .overlay {
        background: linear-gradient(
            52deg,
            var(--secondaryColorOpaque) 0%,
            var(--primaryColorOpaque) 50%,
            var(--secondaryColorOpaque) 100%
        );
        opacity: 0.7;
    }
}

.side-navigator-container [class^='icon-'],
.side-navigator-container [class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
}

.app-container {
    .zacView {
        .zac-wrapper-container {
            position: absolute;
            display: flex;
            flex-direction: column;
            padding-top: 0.875rem;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            padding-bottom: 1.25rem;
        }
    }
    .projectable-form-wrapper {
      padding-top: 1.25rem;
    }
}

.zacView {
    --white: var(--navigation);

    div.ziti-page-container {
        .header {
            .line {
                background: var(--inputBorder);
            }
        }
    }

    .line {
        background-color: var(--inputBorder);
    }

    .ag-header-cell-text {
        position: relative;
        float: left;
        color: var(--tableText);
        font-size: 0.875rem;
        font-weight: 600;
        font-family: Open Sans;
        background-color: var(--navigation) !important;
        border-left: 0.25rem solid var(--navigation) !important;
        height: 3.4375rem;
        transition: 0.5s;
        white-space: nowrap;
    }

    .action-button {
        text-align: center;
        color: var(--white);
        line-height: 3.75rem;
        font-size: 1.875rem;
        width: 3.75rem;
        height: 3.75rem;
        border-radius: 2.5rem;
        position: absolute;
        right: 2.1875rem;
        top: 1.5625rem;
        background-color: var(--primary);
        cursor: pointer;
        overflow: hidden;
        z-index: 24;
        box-shadow: var(--shadow);
        transition: var(--transition);
    }

    .modal.box.full .sized {
        height: 100%;
    }

    .header {
        .tabs {
            .tab {
                &:hover {
                    border-bottom-color: var(--inputBorder);
                }
            }
        }
    }
    #ItemTable.ziti-ag-grid .ag-root-wrapper {
      .ag-row {
        &.ziti-table-row {
          background-color: var(--navigation) !important;

          &.ag-row-odd {
            background-color: var(--background) !important;
          }
          .cell-menu-container .dots {
            font-weight: 600;
            font-size: 1.5rem;
            right: 1.75rem;
          }
        }
      }
    }
}

.cdk-overlay-container {
  #ItemTable .ag-root-wrapper {
    .ag-row {
      background-color: var(--navigation) !important;

      &.ag-row-odd {
        background-color: var(--formBackground) !important;
      }
    }
  }
  #IdentityVisualizerDataModal .identity-topology-container {
    background: #d4f2ff;
    background-image: linear-gradient(#d4eaff 0.125rem, transparent 0), linear-gradient(90deg, #d4eaff 0.125rem, transparent 0), linear-gradient(#d4eaff 1px, transparent 0), linear-gradient(90deg, #d4eaff 1px, transparent 0);
    background-size: 2.5rem 2.5rem, 2.5rem 2.5rem, 0.625rem 0.625rem, 0.625rem 0.625rem;
    .ep-topology-filter-container {
      padding: 0;
    }
  }
}

.ziti-page-container {
    .searchButton {
        display: none;
    }
}

.ziti-page-container {
    height: 100%;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    padding-top: 0.875rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    top: 0rem;

    lib-data-table {
        display: flex;
        height: 100%;
        width: 100%;
    }
}

#ItemTable.nf-ag-grid .ag-root-wrapper .ag-input-field-input {
    height: 100%;
    margin-right: 0rem;
    width: 100%;
    box-shadow: 0 0rem 0.3125rem 0 var(--primaryColor);
    border: none !important;
    overflow: visible;
}

.ziti-page-container #ItemTable.nf-ag-grid {
    position: absolute !important;
}

.cdk-overlay-container,
.mopView {
    .cardStack {
        .title {
            color: var(--tableText) !important;
            &.nobottom {
                border-bottom-width: 0;
            }
        }
    }
    .confirm-modal-container {
        button,
        .button {
            &.confirm {
                background-color: var(--red) !important;
            }
        }
    }

    button,
    .button {
        &.confirm {
            background-color: var(--primaryColor);
        }
    }

    .title {
        color: var(--primaryColor) !important;
    }
    .pageTitle {
        color: var(--primaryColor) !important;
    }
    .openZitiOnly {
        display: none !important;
    }
    .highcharts-background {
        fill: rgba(0, 0, 0, 0);
    }

    .highcharts-grid .highcharts-xaxis-grid .highcharts-grid-line {
        fill: var(--primaryColor);
    }

    :focus {
        outline: 0rem;
    }

    /* Html Element Styling */

    .nf-cell-vert-align {
        display: flex;
        align-items: center;
    }

    .load-indicator {
        position: absolute;
        display: flex;
        height: calc(100% - 6.875rem);
        width: 100%;
        z-index: 9999;

        .loader {
            margin-top: -4.6875rem !important;
        }
    }

    .load-indicator:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        background-color: var(--table);
        opacity: 0.2;
    }

    #EntitiesBulkUpload .ag-header-icon {
        opacity: 0 !important;
        pointer-events: none;

        &.ag-header-col-hide-button,
        &.ag-header-col-pin-button {
            display: none !important;
        }
    }

    #ProcessesTable #ItemTable.nf-ag-grid .ag-root-wrapper .ag-row.ag-row-even.row-child.row-child-even.ag-row-hover,
    #ProcessesTable #ItemTable.nf-ag-grid .ag-root-wrapper .ag-row.ag-row-even.row-child.row-child-odd.ag-row-hover,
    #ProcessesTable #ItemTable.nf-ag-grid .ag-root-wrapper .ag-row.ag-row-odd.row-child.row-child-odd.ag-row-hover,
    #ProcessesTable #ItemTable.nf-ag-grid .ag-root-wrapper .ag-row.ag-row-odd.row-child.row-child-even.ag-row-hover {
        background-color: rgba(33, 150, 243, 0.1);
    }

    #ProcessesTable .ag-header-icon {
        opacity: 0 !important;
        pointer-events: none;

        &.ag-header-col-hide-button,
        &.ag-header-col-pin-button {
            display: none !important;
        }
    }

    #ItemTable.nf-ag-grid .ag-root-wrapper {
        border: none;

        .ag-row {
            border: none;
            background-color: var(--n);

            &.ag-row-odd {
              background-color: var(--formBackground);
            }

            &.row-invalid {
                background-color: var(--invalid) !important;

                &.row-copied {
                    background-color: var(--copiedRow) !important;
                }
            }

            &.row-copied {
                background-color: var(--copiedRow) !important;
            }

            &.ag-row-selected {
                background-color: var(--navigation);

                &.ag-row-hover {
                    background-color: rgba(33, 150, 243, 0.1);
                }
            }

            &.ag-row-odd {
                background-color: var(--background);

                &.ag-row-selected {
                    background-color: var(--background);

                    &.ag-row-hover {
                        background-color: rgba(33, 150, 243, 0.1);
                    }
                }

                &.ag-row-hover {
                    background-color: rgba(33, 150, 243, 0.1);
                }
            }

            &.ag-row-hover {
                background-color: rgba(33, 150, 243, 0.1);
            }

            .dots {
                right: 1.5625rem;
                color: var(--tableText);
            }

            &.row-role-item {
                .tCol {
                    font-size: 0.8125rem;
                    height: 2.1875rem;
                    line-height: 2.1875rem;
                    font-weight: 600;
                }
            }
        }

        .ag-cell {
            border: none;

            &.ag-cell-value {
                float: left;
                font-size: 0.9375rem;
                font-weight: 400;
                font-family: Open Sans;
                text-overflow: ellipsis;
                height: 3.125rem;
                overflow: hidden;
                white-space: nowrap;

                &.nf-table-cell-error {
                    outline: 0.125rem var(--red) solid;
                    outline-offset: -0.1875rem;
                }

                &.ag-cell-inline-editing {
                    width: 100%;
                    height: 100%;
                    padding-left: 0;
                }
            }
        }

        .ag-header-cell:focus:after {
            border: none;
        }

        .ag-pinned-left-header {
            border: none;

            .ag-icon-pin {
                margin-top: 0.9375rem;
                transform: rotate(-45deg);
            }

            .ag-header-cell-text {
                cursor: default;
            }

            .ag-header-col-pin-button:hover {
                .ag-icon-pin {
                    animation-name: unpinColumn;
                    animation-duration: 0.5s;
                    animation-delay: 0.1s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                }
            }
        }

        .ag-header-col-pin-button:hover {
            .ag-icon-pin {
                animation-name: pinColumn;
                animation-duration: 0.6s;
                animation-delay: 0.1s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
        }

        .ag-row-level-1 app-table-cell-resource-status app-resource-status {
            margin-left: 2.5rem;

            .bubble {
                height: 0.6875rem;
                width: 0.6875rem;

                .circular {
                    margin-left: 2.5rem;
                }
            }
        }

        .ag-icon-eye-slash {
            margin-top: 0.75rem;
            margin-right: 0.3125rem;
        }

        .ag-icon-pin {
            margin-top: 0.75rem;
        }

        @keyframes pinColumn {
            0% {
                transform: rotate(0deg);
                margin-top: 0.75rem;
            }
            15% {
                transform: rotate(-10deg);
                margin-top: 0.75rem;
            }
            30% {
                transform: rotate(-20deg);
                margin-top: 0.75rem;
            }
            45% {
                transform: rotate(-30deg);
                margin-top: 0.8125rem;
            }
            60% {
                transform: rotate(-40deg);
                margin-top: 0.875rem;
            }
            75% {
                transform: rotate(-45deg);
                margin-top: 0.9375rem;
            }
            100% {
                transform: rotate(-45deg);
                margin-top: 1rem;
            }
        }

        @keyframes unpinColumn {
            0% {
                transform: rotate(-45deg);
                margin-top: 0.9375rem;
            }
            15% {
                transform: rotate(-45deg);
                margin-top: 0.875rem;
            }
            30% {
                transform: rotate(-45deg);
                margin-top: 0.8125rem;
            }
            45% {
                transform: rotate(-40deg);
                margin-top: 0.75rem;
            }
            60% {
                transform: rotate(-30deg);
                margin-top: 0.6875rem;
            }
            75% {
                transform: rotate(-20deg);
                margin-top: 0.625rem;
            }
            90% {
                transform: rotate(-10deg);
                margin-top: 0.5625rem;
            }
            100% {
                transform: rotate(0deg);
                margin-top: 0.5rem;
            }
        }

        .ag-pinned-right-header {
            border: none;
        }

        .selectHeader {
            background-image: none;
        }

        .menuHeader {
            background-image: none;
        }

        .ag-pinned-right-header {
            .ag-header-row:after {
                height: 0;
            }
        }

        .ag-horizontal-left-spacer {
            border: none;
        }

        .ag-horizontal-right-spacer {
            border: none;
        }

        .ag-header-cell-resize {
            margin-top: 0.125rem;
            border-left-color: #d4d9e2;
            border-right-color: #d4d9e2;
            border-left-width: 1px;
            border-right-width: 1px;
            border-left-style: solid;
            border-right-style: solid;
            width: 0.1875rem;
            height: 0.875rem;
            position: absolute;
            right: 0.125rem;
            top: 1.1875rem;
            cursor: col-resize;
        }

        .ag-header-cell-resize:after {
            display: none;
        }

        .ag-header-cell-text {
            height: 3rem;
            cursor: move;
        }

        .ag-icon-menu {
            margin-top: 0.25rem;
        }

        .ag-menu {
            margin-left: -2.8125rem;
            z-index: 99999999;
        }

        .ag-header-cell {
            padding-right: 0.3125rem;
        }

        .ag-input-field-input {
            height: 100%;
            margin-right: 0rem;
            width: 100%;
            box-shadow: 0 0rem 0.3125rem 0 var(--primaryColor);
            border: solid 0.125rem var(--primaryColor);
            overflow: visible;
        }

        .ag-header-viewport {
            box-sizing: border-box;
            border-bottom: 0.25rem solid var(--primaryColor);
            background-color: var(--navigation);
            padding-left: 0.25rem;
            line-height: 3.4375rem;
            position: -webkit-sticky !important;
            position: sticky !important;
            top: 0;
            z-index: 10;
            transition: all 0.15s linear;
            opacity: 0.97;
        }

        .ag-cell-label-container {
            cursor: default;

            &.nf-can-sort {
                cursor: pointer;
            }
        }

        .ag-header {
            background-color: var(--navigation);
            border-bottom: solid 1px;
            border-bottom-color: var(--navigation);
        }

        .ag-header-icon {
            color: var(--icon);
        }

        .ag-icon-pin {
            font-weight: 800;
            margin-right: 0.3125rem;
        }

        .ag-icon-menu {
            font-weight: 800;
        }

        .ag-icon-eye-slash {
            font-weight: 800;
            color: var(--icon);
        }

        .ag-header-cell-resize {
            border-left-color: var(--offWhite);
            border-right-color: var(--offWhite);
            border-left-width: 0.125rem;
            border-right-width: 0.125rem;
            border-left-style: solid;
            border-right-style: solid;
            width: 0.3125rem;
        }

        .ag-header-cell-resize:hover {
            border-left-color: var(--icon);
            border-right-color: var(--icon);
        }

        .ag-header-viewport,
        .ag-floating-top-viewport,
        .ag-body-viewport,
        .ag-center-cols-viewport,
        .ag-floating-bottom-viewport,
        .ag-body-horizontal-scroll-viewport,
        .ag-virtual-list-viewport {
            background-color: var(--navigation);
        }

        .ag-theme-alpine .ag-row {
            background-color: var(--navigation);
        }

        .tSelect {
            left: 1.25rem;
        }

        .ag-pinned-left-shadow {
            box-shadow: 0.5rem 0 0.3125rem -0.25rem #dddd;
            z-index: 40;
        }

        .ag-pinned-right-shadow {
            box-shadow: -0.5rem 0 0.3125rem -0.25rem #dddd;
            z-index: 40;
        }

        .ag-pinned-left-header {
            .ag-header-row-column {
                border-bottom-color: var(--inputBorder);
            }
        }

        .ag-pinned-right-header {
            .ag-header-row-column {
                border-bottom-color: var(--inputBorder);
            }

            .dots {
                right: 0.25rem;
            }
        }

        .ag-cell {
            -webkit-user-select: text;
            -moz-user-select: text;
            -ms-user-select: text;
            user-select: text;
        }

        .ag-body-viewport {
            .ag-cell {
                padding-left: 1.25rem;
                text-align: left;
                line-height: 3.0625rem !important;
            }
        }

        .ag-pinned-left-cols-container {
            .ag-row {
                border-left: 0.25rem solid transparent;
            }

            .ag-row-hover {
                border-left: 0.25rem solid var(--primaryColor);
            }

            .tSelect {
                margin-left: -0.25rem;
            }
        }

        .ag-cell-label-container {
            .ag-header-col-hide-button,
            .ag-header-col-pin-button,
            .ag-header-col-filter-button {
                margin-top: 0.3125rem;
                opacity: 0;
                transition: opacity 0.2s;
                height: 2.5rem;
                cursor: pointer;

                &.nf-ag-col-pinned {
                    opacity: 1;
                }

                &:hover {
                    .ag-icon-eye-slash,
                    .ag-icon-pin,
                    .ag-icon-filter {
                        color: var(--ag-alpine-active-color, var(--primaryColor));
                    }
                }

                .ag-icon-filter {
                    font-weight: bold;
                    margin-top: 0.8125rem;
                    margin-right: 0rem;
                    margin-left: -1px;
                }
            }

            .ag-header-col-hide-button,
            .ag-header-col-pin-button {
                position: absolute;
                margin-top: 0.625rem !important;
            }

            .ag-header-col-hide-button {
                margin-right: 1.3125rem;
            }

            &:hover {
                .ag-header-col-hide-button,
                .ag-header-col-pin-button,
                .ag-header-col-filter-button {
                    position: relative;
                    display: block;
                    opacity: 1;
                }

                .ag-header-col-hide-button {
                    margin-right: 0rem;
                }
            }
        }

        .tSelectHeader {
            top: 0.75rem;
        }

        .ag-header-cell {
            padding-left: 0rem;
        }

        .ag-sort-descending-icon {
            margin-top: 0.9375rem;
        }

        .nf-ag-main-col {
            .ag-cell-label-container {
                margin-left: -0.5rem;

                .ag-header-cell-text {
                    padding-left: 0.1875rem;
                }
            }
        }

        .nf-ag-grid .ag-root-wrapper .ag-row.row-child {
            background-color: white;
            border-bottom: solid var(--inputBorder) 1px;
        }

        app-table-header-default {
            width: 100%;
        }

        app-table-cell-registration {
            width: 100%;

            .tcol {
                width: 100%;
            }
        }

        .ag-row.ag-row-even.row-child.row-child-odd,
        .ag-row.ag-row-odd.row-child.row-child-odd {
            background-color: var(--table);

            &.row-role-item {
                background-color: var(--background);
                border: none;

                &.ag-row-hover {
                    background-color: rgba(33, 150, 243, 0.1);
                }
            }

            &.row-child-second {
                background-color: var(--background);

                &.row-child-header {
                    background-color: var(--background);
                }
            }

            &.row-child-warn {
                background-color: var(--copiedRow);
            }

            &.row-child-header {
                background-color: var(--background);
            }
        }

        .ag-row.ag-row-even.row-child.row-child-even,
        .ag-row.ag-row-odd.row-child.row-child-even {
            background-color: var(--background);

            &.row-role-item {
                background-color: var(--table);
                border: none;

                &.ag-row-hover {
                    background-color: rgba(33, 150, 243, 0.1);
                }
            }

            &.row-child-second {
                background-color: var(--table);

                &.row-child-header {
                    background-color: var(--table);
                }
            }

            &.row-child-warn {
                background-color: var(--copiedRow);
            }

            &.row-child-header {
                background-color: var(--table);
            }
        }

        .row-child {
            border-top: solid var(--inputBorder) 1px;

            &.row-child-second {
                border-top: transparent;

                .ag-cell {
                    font-size: 0.6875rem;
                }
            }

            .bubble {
                width: 0.5rem;
                height: 0.5625rem;
                margin-left: 0.3125rem;
                margin-bottom: 1px;
            }

            .ag-cell {
                font-size: 0.8125rem;
            }

            app-resource-status {
                margin-left: 1.4375rem;

                .circular {
                    height: 0.75rem;
                    margin-left: 1.125rem;
                }

                > span {
                    font-size: 0.8125rem;
                    margin-left: 0.25rem;
                }
            }

            &.row-child-second {
                app-resource-status {
                    margin-left: 1.625rem;

                    .circular {
                        margin-left: 1.625rem;
                    }

                    > span {
                        font-size: 0.8125rem;
                        margin-left: 0.25rem;
                    }
                }
            }
        }

        .row-child-second {
            .step-header-container {
                .step-content {
                    position: relative;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    flex-grow: 1;
                    flex-shrink: 0;
                    padding-right: 0.3125rem;
                }

                .step-label {
                    padding-left: 3.4375rem;

                    &.step-content {
                        padding-left: 0;
                    }

                    margin-left: 1.25rem;
                    width: calc(20% + 1.25rem);
                }

                .started-label {
                    width: calc(20% + 1.25rem);
                    overflow: hidden;
                    flex-grow: 1;
                    flex-shrink: 0;
                }

                .finished-label {
                    width: calc(20% + 1.25rem);
                    overflow: hidden;
                    flex-grow: 1;
                    flex-shrink: 0;
                }

                .duration-label {
                    width: calc(20% + 1.25rem);
                    overflow: hidden;
                    flex-grow: 1;
                    flex-shrink: 0;
                }

                .state-label {
                    width: calc(20% + 1.25rem);
                    overflow: hidden;
                    flex-grow: 1;
                    flex-shrink: 0;
                }
            }
        }

        .process-info-container {
            app-resource-status {
                .circular {
                    margin-left: 0.8125rem;
                    margin-top: -1px;
                }
            }
        }

        app-table-cell-resource-status {
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-left: -0.625rem;

            .tree-table {
                .bubble {
                    .circular {
                        // margin-left: 1rem;
                    }
                }
            }

            .bubble {
                margin-left: 0.125rem;

                .circular {
                    left: 0;
                    top: 1rem;
                }
            }
        }
    }

    app-data-table,
    app-data-table2 {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    body {
        .ag-theme-alpine {
            &.ag-dnd-ghost {
                height: 2.1875rem !important;
                line-height: 2.1875rem !important;
            }
        }

        overflow-x: auto !important;
    }

    .ag-header-row {
        box-sizing: border-box;
        border-bottom: 0.25rem solid var(--primaryColor);
        background-color: var(--navigation);
        padding-left: 0.25rem;
        line-height: 3.4375rem;
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0;
        z-index: 10;
        transition: all 0.15s linear;
        opacity: 0.97;
    }

    .ag-header-cell-text {
        position: relative;
        float: left;
        color: var(--tableText);
        font-size: 0.875rem;
        font-weight: 600;
        font-family: Open Sans;
        height: 3.4375rem;
        transition: 0.5s;
        white-space: nowrap;
    }

    .ag-header-cell-resize:after {
        display: none;
    }

    .ag-header-cell-menu-button {
        height: 1rem;
        width: 1rem;
    }

    .ag-icon {
        font-family: agGridAlpine;
        font-size: 1rem;
        line-height: 1rem;
        font-style: normal;
        font-weight: 800;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .ag-icon-menu:before {
        content: '\f11b';
    }

    .ag-icon-menu:hover {
        color: var(--primaryColor);
    }

    .ag-icon-asc {
        margin-top: 0.4375rem;
        font-weight: 800;
    }

    .ag-icon-desc {
        margin-top: -0.4375rem;
        font-weight: 800;
    }

    .tSelectHeader {
        left: 1.3125rem;
        top: 0.625rem;
    }

    .cellMenu {
        overflow: visible;
    }

    .ag-theme-alpine {
        -webkit-font-smoothing: antialiased;
        color: var(--tableText) !important;
        font-family: 'Open Sans', sans-serif;
        font-size: 0.8125rem;
        line-height: normal;
    }

    .ag-theme-alpine .ag-row {
        color: var(--tableText) !important;
    }

    .ag-theme-alpine .ag-header-cell,
    .ag-theme-alpine .ag-header-group-cell {
        padding-left: 0.3125rem;
        padding-right: 0.9375rem;
    }

    nav {
        border-top-color: var(--offWhite);
        border-top-style: solid;
        border-top-width: 1px;
        width: 3.75rem;
        position: fixed;
        left: 0rem;
        top: 3.75rem;
        bottom: 0rem;
        z-index: 26;
        background-color: var(--white);
        border-right-color: var(--offWhite);
        border-right-style: solid;
        border-right-width: 1px;
        -webkit-box-shadow: 0rem 0.5rem 0.5rem 0.125rem var(--primaryColorShadowLight);
        -moz-box-shadow: 0rem 0.5rem 0.5rem 0.125rem var(--primaryColorShadowLight);
        box-shadow: 0rem 0.5rem 0.5rem 0.125rem var(--primaryColorShadowLight);
    }

    nav .icon {
        width: 3.75rem;
        height: 3.75rem;
        background-size: 2.125rem 2.125rem;
        background-position: center center;
        cursor: pointer;
        position: absolute;
        top: 0rem;
        left: 0rem;
    }

    nav .iconSelected {
        width: 3.75rem;
        height: 3.75rem;
        background-size: 2.125rem 2.125rem;
        background-position: center center;
        cursor: pointer;
        display: none;
        position: absolute;
        top: 0rem;
        left: 0rem;
    }

    nav .navItem {
        position: relative;
        clear: both;
        width: 100%;
        height: 3.75rem;
        cursor: pointer;
    }

    nav .navLabel {
        font-size: 0.875rem;
        line-height: 3.75rem;
        padding-left: 4.375rem;
        color: var(--stroke);
        text-transform: uppercase;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        height: 3.75rem;
        width: 18.75rem;
        font-weight: 600;
        font-family: 'Open Sans';
    }

    nav .navLabel.navSelected {
        color: var(--primaryColor);
    }

    /* Single Element Styles */

    #NetworkTimeline {
        padding-right: 1.5625rem;
        padding-top: 0.625rem;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 5;
    }

    #AlarmList {
        max-height: 22.125rem;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 5;
    }

    #ListArea .search {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        margin-top: -0.4375rem;
    }

    #ListArea .item {
        width: 100%;
        height: 2rem;
        clear: both;
        position: relative;
        float: left;
    }

    #ListArea .item .icon::before {
        color: var(--white);
    }

    #ListArea .item .icon {
        width: 1.625rem;
        height: 1.625rem;
        background-size: contain;
        position: absolute;
        left: 0.625rem;
        top: 0.1875rem;
        background-image: unset;
        font-size: 1.125rem;
        text-align: center;
        line-height: 1.625rem;
    }

    #ListArea .item .content {
        position: relative;
        float: left;
        font-size: 0.8125rem;
        line-height: 2rem;
        color: var(--white);
        font-weight: 600;
        font-family: 'Open Sans';
    }

    #ListArea .addNewNetwork:hover {
        background-color: var(--offWhite);
    }

    /* Multiple Element Styles */
    .label {
        color: var(--tableText);
        text-transform: uppercase;
        margin-top: 1.5625rem;
        margin-bottom: 0.6875rem;
        font-size: 0.875rem;
        font-weight: 600;
        font-family: 'Open Sans';
    }

    .label.error {
        color: var(--red);
    }

    .editor {
        position: relative;
        display: inline-block;
        width: 1.1875rem;
        height: 0.9375rem;
        top: 0.1875rem;
        background-position: center center;
        cursor: pointer;
        background-size: contain;
        background-image: url(/assets/svgs/Edit.svg);
        transition: 0.5s;
    }

    .editor:hover {
        opacity: 0.6;
    }

    .clicker {
        position: relative;
        display: inline-block;
        width: 1.375rem;
        height: 0.875rem;
        top: 0.1875rem;
        background-position: center center;
        cursor: pointer;
        background-size: contain;
        background-image: url(/assets/svgs/SmTaptoEditAdvanced.svg);
        transition: 0.5s;
    }

    .clicker:hover {
        opacity: 0.6;
    }

    .fakeInput {
        font-weight: 400;
        font-family: 'Open Sans', Arial, sans-serif;
        height: 3.125rem;
        width: 100%;
        border-style: solid;
        border-width: 1px;
        border-radius: 0.1875rem;
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        outline: none;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        font-size: 0.9375rem;
        text-align: center;
        line-height: 3.125rem;
        font-weight: bold;
        cursor: pointer;
        color: var(--tabletext);
        border-color: var(--inputBorder);
        background-color: var(--inputBorder) !important;
    }

    .inputAppend {
        font-size: 0.875rem;
        position: absolute;
        right: 0.125rem;
        top: 0.125rem;
        bottom: 0.125rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        text-align: center;
        border-bottom-right-radius: 0.1875rem;
        border-top-right-radius: 0.1875rem;
        line-height: 3rem;
        font-weight: bold;
        color: var(--tabletext);
        background-color: var(--inputBorder);
    }

    .expansion {
        margin-top: 0.3125rem;
    }

    .labelExpansion {
        margin-bottom: 0.375rem;
    }

    .mat-expansion-panel {
        background-color: #232f3e !important;
        color: #fff !important;
        border-radius: 0.4375rem !important;
        box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow) !important;
        overflow: visible !important;
    }

    .mat-expansion-indicator:after {
        color: white !important;
    }

    .expansion-indicator {
        right: 1.875rem;
        font-size: 2.375rem;
        font-weight: 600;
        top: 0.9375rem;
        right: 0.75rem;
        text-align: center;
        position: absolute;
        transition: 0.5s ease;
    }

    .expansion-indicator.open {
        transform: rotate(-180deg);
    }

    .expansion-indicator.down {
        background-image: url(/assets/svgs/ArrowDown.svg);
    }

    .expansion-indicator.up {
        background-image: url(/assets/svgs/ArrowUp.svg);
    }

    .mat-expansion-panel-header {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
        border-radius: 0.3125rem !important;
        height: 4.0625rem !important;
    }

    .mat-expansion-panel-header-title {
        height: 4.0625rem !important;
        margin-right: 0rem !important;
    }

    .mat-expansion-panel-body {
        padding-left: 0.8125rem !important;
        padding-right: 0.8125rem !important;
        padding-bottom: 0.4375rem !important;
    }

    .mat-grid-tile .label {
        height: 100%;
        width: 100%;
        color: #fff !important;
    }

    .label.expansionLabel {
        margin-top: 0rem;
        margin-bottom: 0rem;
    }

    .expansion .label {
        color: #fff !important;
    }

    .title {
        color: var(--tableTitle);
        font-size: 1.75rem;
    }

    .subtitle {
        color: var(--stroke);
        font-size: 0.9375rem;
    }

    .padding {
        padding: 0.625rem;
    }

    .hidden {
        display: none;
    }

    .shadowBig {
        -webkit-box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadowThick);
        -moz-box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadowThick);
        box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadowThick);
    }

    .padLeft {
        padding-left: 2rem;
    }

    .muted {
        color: var(--placeholder) !important;
    }

    .note {
        color: var(--placeholder);
        font-size: 0.875rem;
        position: absolute;
        right: 0rem;
        bottom: 0rem;
        text-transform: uppercase;
        font-weight: 600;
    }

    .mobileOnly {
        display: none;
    }

    .greyArea {
        background-color: var(--navigation);
        position: relative;
        width: 100%;
        float: left;
        clear: both;
        padding-bottom: 2.5rem;
        background-position: center center;
    }

    .overlay {
        left: 0rem;
        top: 0rem;
        right: 0rem;
        bottom: 0rem;
        position: absolute;
        background: -moz-linear-gradient(to right, rgba(0, 0, 0, 0.58) 0%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(to right, rgba(0, 0, 0, 0.58) 0%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(to right, rgba(0, 0, 0, 0.58) 0%, rgba(0, 0, 0, 0) 100%);
    }

    .noarea {
        cursor: pointer;
    }

    .noarea .subtitle {
        font-size: 1.875rem;
        text-align: center;
        font-weight: 300;
        font-family: 'Open Sans';
    }

    .noarea .icon {
        width: 100%;
        height: 15.625rem;
        margin-bottom: 1.875rem;
        background-position: center center;
        background-size: contain;
        position: relative;
        float: left;
        z-index: unset;
        left: unset;
        cursor: pointer;
        transition: 0.3s;
    }

    .noarea .icon:hover {
        filter: brightness(100%);
        transition: 0.3s;
    }

    .noarea .icon:active {
        filter: brightness(90%);
        transform: scale(0.97);
        transition: 0.3s;
    }

    .noarea .title {
        margin-top: 3.125rem;
        text-align: center;
    }

    .noarea .subtitle {
        text-align: center;
        margin-left: 4.375rem;
    }

    .created {
        background-image: url(/assets/svgs/Icon_Created.svg);
        background-position: center center;
        background-size: contain;
        width: 100%;
        height: 7.5rem;
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;
    }

    .building {
        background-image: url(/assets/svgs/GatewayProvisioning.svg);
        background-position: center center;
        background-size: contain;
        width: 100%;
        height: 7.5rem;
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;
    }

    .clientCreated {
        background-image: url(/assets/svgs/Client_Created.svg);
        background-position: center center;
        background-size: 11.25rem;
        width: 100%;
        height: 8.125rem;
        margin-left: 0.625rem;
    }

    .clientBuilding {
        background-image: url(/assets/svgs/ProvisioningClient.svg);
        background-position: center center;
        background-size: 11.25rem;
        width: 100%;
        height: 8.125rem;
        margin-left: 0.625rem;
    }

    .labelLink {
        position: absolute;
        right: 0rem;
        bottom: 0rem;
        color: var(--stroke);
        cursor: pointer;
        text-transform: none;
        font-size: 0.8125rem;
    }

    .labelLink:hover {
        text-decoration: underline;
    }

    .subcontent {
        color: var(--tableText);
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: 600;
    }

    .counter {
        display: inline-block;
        background-color: var(--inputBorder);
        color: var(--tableText);
        font-weight: bold;
        text-align: center;
        border-radius: 0.875rem;
        height: 1.5rem;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
        line-height: 1.5rem;
        margin-left: 0.625rem;
        font-size: 0.8125rem;
    }

    .noadd {
        width: 1.875rem;
        height: 1.875rem;
        display: inline-block;
        background-size: contain;
        background-image: url(/assets/images/No_Plus.png); //REPLACE
    }

    .subText {
        font-size: 0.875rem;
        color: var(--stroke);
        line-height: 1rem;
        margin-top: 0.1875rem;
    }

    .sized.mid {
        width: 37.5rem;
    }

    .sized {
        width: 43.75rem;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0rem;
    }

    .tileSized {
        width: 76.5rem;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0rem;
        text-align: center;
    }

    .tileSized {
        width: 76.5rem;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0rem;
    }

    .listSized {
        width: 43.75rem;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0rem;
    }

    .midSized {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .bigSized {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .largeSized {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .fullSized {
        margin-left: 1.25rem;
        margin-right: 1.25rem;
    }

    .fullColor {
        position: absolute;
        top: 0rem;
        bottom: 0rem;
        left: 0rem;
        right: 0rem;
        background-color: var(--primaryColor);
    }

    .betaInline {
        color: var(--primaryColorHover);
        border: solid var(--primaryColorHover) 1px;
        border-radius: 0.4375rem;
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: bold;
        padding: 0.125rem;
        line-height: 0.75rem;
        padding-left: 0.625rem;
        padding-right: 0.625rem;
    }

    .clickable.link {
        color: var(--primaryColor);
        transition: 0.5s ease;
        font-weight: 600;
    }

    .clickable.link:hover {
        color: var(--tableText);
    }

    .clickable {
        cursor: pointer;
    }

    .shadow {
        -webkit-box-shadow: 0rem 0rem 0.5rem 0.125rem var(--primaryColorShadowLight);
        -moz-box-shadow: 0rem 0rem 0.5rem 0.125rem var(--primaryColorShadowLight);
        box-shadow: 0rem 0rem 0.5rem 0.125rem var(--primaryColorShadowLight);
    }

    .right {
        text-align: right;
        line-height: 1rem;
        color: var(--stroke);
    }

    .slash {
        color: var(--secondaryColor);
    }

    .hr {
        width: 100%;
        position: relative;
        float: left;
        clear: both;
        height: 1px;
        background-color: var(--offWhite);
        margin-top: 1.875rem;
        margin-bottom: 1.0625rem;
    }

    .darken {
        background-color: rgba(0, 0, 0, 0.58);
        position: absolute;
        left: 0rem;
        right: 0rem;
        top: 0rem;
        bottom: 0rem;
        border-top-left-radius: 0.4375rem;
        border-top-right-radius: 0.4375rem;
        z-index: 1;
    }

    .darkenText {
        z-index: 5;
        position: relative;
    }

    .footer {
        position: relative;
    }

    .footer .darken {
        background-color: rgba(0, 0, 0, 0.58);
        position: absolute;
        left: 0rem;
        right: 0rem;
        top: 0rem;
        bottom: 0rem;
        border-bottom-left-radius: 0.4375rem;
        border-bottom-right-radius: 0.4375rem;
        border-top-left-radius: 0rem;
        border-top-right-radius: 0rem;
        z-index: 1;
    }

    /* Timeline Styles */
    .timelineLine {
        width: 0.125rem;
        background-color: var(--icon);
        height: 0.625rem;
        position: absolute;
        top: 5rem;
        left: 3.125rem;
        z-index: 4;
        display: none;
    }

    .connector {
        width: 0.125rem;
        background-color: var(--icon);
        height: 1.1875rem;
        position: absolute;
        top: -1.25rem;
        left: 3.125rem;
        z-index: 4;
        margin-top: 0;
    }

    .timeline {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        width: 100%;
        position: relative;
        float: left;
        z-index: 5;
    }

    .timeline .date {
        height: 2.75rem;
        line-height: 2.75rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        background-color: var(--primaryColor);
        color: var(--white);
        font-weight: 600;
        border-radius: 1.375rem;
        display: inline-block;
        margin-bottom: 0.625rem;
        margin-top: 0.625rem;
        border: none;
    }

    .alarmItem {
        width: 100%;
        height: 4rem;
        line-height: 4rem;
        border-bottom-color: var(--inputBorder);
        border-bottom-style: solid;
        border-bottom-width: 0.125rem;
        display: grid;
        grid-template-columns: 4rem auto 6.25rem;
        overflow: hidden;
        font-weight: 600;
        padding-right: 0.9375rem;
        transition: 0.3s;
    }

    .alarmItem:hover {
        background-color: var(--offWhite);
        transition: 0.3s;
    }

    .alarmItem:active {
        background-color: var(--background);
        filter: brightness(90%);
        transition: 0.3s;
    }

    .alarmItem .alarmDate {
        font-size: 0.875rem;
        text-align: right;
        font-weight: 600;
    }

    /* Icon and Logo Styles */
    .logo {
        background-image: var(--logoLight);
        position: absolute;
        right: 7.5rem;
        top: 0.8125rem;
        width: 13.5rem;
        height: 2.1875rem;
        background-position: center right;
        background-repeat: no-repeat;
        -webkit-background-size: 13.5rem 2.1875rem;
        -moz-background-size: 13.5rem 2.1875rem;
        background-size: 13.5rem 2.1875rem;
    }

    .logo.developmentSandbox {
        background-image: url('/assets/images/Sandbox-Development.png');
    }

    .logo.integration {
        background-image: url('/assets/images/Integration.png');
    }

    .logo.staging {
        background-image: url('/assets/images/Staging.png');
    }

    .logo.trial {
        background-image: url('/assets/images/Trial.png');
    }

    .logo.dark {
        background-image: var(--logoDark);
    }

    .logo.dark.logo.developmentSandbox {
        background-image: url('/assets/images/sandboxdark.png');
    }

    .logo.dark.logo.integration {
        background-image: url('/assets/images/integrationdark.png');
    }

    .logo.dark.logo.staging {
        background-image: url('/assets/images/stagingdark.png');
    }

    .logo.dark.logo.trial {
        background-image: url('/assets/images/trialdark.png');
    }

    .iconList {
        padding: 1.25rem;
    }

    .icontitle {
        font-weight: 600;
        font-family: 'Open Sans', Arial, sans-serif;
        font-size: 0.9375rem;
        font-weight: bold;
        text-align: center;
        color: var(--stroke);
        text-align: center;
        margin-top: 1.25rem;
    }

    .iconsubtitle {
        font-weight: 300;
        font-family: 'Open Sans', Arial, sans-serif;
        font-size: 0.9375rem;
        text-align: center;
        color: var(--stroke);
        text-align: center;
        line-height: 1.5625rem;
        margin-top: 0.625rem;
    }

    .iconIcon {
        width: 100%;
        height: 6.25rem;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .logo.inline {
        background-image: url(/assets/svgs/InlineLogo.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left center;
        height: 2.375rem;
        margin-bottom: 0.25rem;
        right: auto;
        top: auto;
    }

    .logo.inline.developmentSandbox {
        background-image: url(/assets/svgs/inlinesandbox.svg);
        height: 2.8125rem;
        margin-bottom: 0.25rem;
    }

    .logo.inline.integration {
        background-image: url(/assets/svgs/inlineintegration.svg);
        height: 2.8125rem;
        margin-bottom: 0.25rem;
    }

    .logo.inline.staging {
        background-image: url(/assets/svgs/inlinestaging.svg);
        height: 2.8125rem;
        margin-bottom: 0.25rem;
    }

    .logo.inline.trial {
        background-image: url(/assets/svgs/inlinetrial.svg);
        height: 2.8125rem;
        margin-bottom: 0.25rem;
    }

    .icon {
        background-image: url(/assets/svgs/icon.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 6.875rem;
        height: 6.875rem;
        position: fixed;
        left: 0.9375rem;
        top: 0.9375rem;
        z-index: 10;
    }

    .icon.mobile {
        background-image: url(/assets/svgs/icon.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 5.625rem;
        height: 5.625rem;
        position: fixed;
        left: 0.9375rem;
        top: 0.9375rem;
        z-index: 40;
    }

    .iconRow {
        clear: both;
        width: 100%;
        background-position: center center;
        background-size: contain;
        height: 12.5rem;
        margin-top: 1.25rem;
        margin-bottom: 2.5rem;
    }

    /* Form Styles */
    .clearFilter {
        font-family: 'icomoon' !important;
        font-style: normal;
        font-weight: 600;
        font-variant: normal;
        text-transform: none;
        font-size: 1.875rem;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        right: 1.25rem;
        top: 0.625rem;
        width: 1.875rem;
        height: 1.875rem;
        background-size: contain;
        cursor: pointer;
        color: var(--inputBorder);
    }

    .clearFilter:before {
        content: '\e905';
    }

    .clearFilter:hover {
        color: var(--stroke);
        transition: 0.3s;
    }

    .clearFilter:active {
        color: var(--inputBorder);
        transform: scale(0.97);
        transition: 0.3s;
    }

    .squares {
        width: 1.75rem;
        height: 1.75rem;
        background-image: url(/assets/images/Squares.png);
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        display: none;
    }

    .hashtag,
    .attag,
    .notatt {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        background-color: var(--primaryColor) !important;
        color: #fff;
        position: relative;
        float: left;
        line-height: 1.875rem;
        border-radius: 0.4375rem;
        margin-left: 0.4375rem;
        margin-bottom: 0.4375rem;
        margin-top: 0.4375rem;
        transition: 0.2s;
        font-size: 1rem;
        font-weight: 700;
    }

    .attag {
        background-color: #00d256 !important;
    }

    .notatt {
        background-color: var(--icon) !important;
    }

    .searchSelector .tagButton {
        cursor: pointer;
        transition: 0.2s;
    }

    .searchSelector .tagButton:hover {
        opacity: 0.2;
    }

    .searchSelector .hashtag {
        margin-top: unset;
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        color: #fff;
        background-color: var(--primaryColor);
        position: relative;
        float: left;
        line-height: 1.875rem;
        border-radius: 0.4375rem;
        margin-left: 0.625rem;
        margin-bottom: 0.625rem;
        transition: 0.5s;
    }

    .searchSelector .hashtag .label,
    .searchSelector .attag .label,
    .searchSelector .notatt .label {
        font-size: 1rem;
        font-family: 'Open Sans';
        margin-left: 0.3125rem;
    }

    .searchSelector .notatt.error,
    .searchSelector .attag.error,
    .searchSelector .hashtag.error {
        background-color: var(--red);
    }

    .searchSelector .attag {
        margin-top: unset;
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        color: #fff;
        background-color: var(--secondaryColor);
        position: relative;
        float: left;
        line-height: 1.875rem;
        border-radius: 0.4375rem;
        margin-left: 0.625rem;
        margin-bottom: 0.625rem;
        transition: 0.5s;
    }

    .searchSelector .notatt {
        margin-top: unset;
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        color: #fff;
        background-color: var(--icon);
        position: relative;
        float: left;
        line-height: 1.875rem;
        border-radius: 0.4375rem;
        margin-left: 0.625rem;
        margin-bottom: 0.625rem;
        transition: 0.5s;
    }

    .searchSelector {
        border-radius: 0.4375rem;
        border-width: 0.125rem;
        border-color: var(--inputBorder);
        background-color: var(--white);
        border-style: solid;
        width: 100%;
        position: relative;
        overflow: auto;
        transition: 0.5s;
    }

    .searchSelector input {
        border-color: var(--white);
    }

    .searchSelector input:focus {
        box-shadow: none !important;
        border: 0rem !important;
    }

    .darkBox {
        background-color: #232f3e;
        width: 100%;
        position: relative;
        border-radius: 0.4375rem;
        color: #fff;
        --mdc-theme-text-primary-on-background: #fff;
        display: inline-block;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-top: 0.9375rem;
        padding-bottom: 0.9375rem;
        --mdc-typography-body2-font-family: Open Sans, sans-serif;
        --mdc-typography-body2-font-size: 0.9375rem;
        --mdc-typography-body2-font-weight: 600;
    }

    .darkBox .icon {
        height: 2.5rem;
        width: 2.5rem;
        background-position: center center;
        background-size: contain;
        min-height: unset;
        top: unset;
        left: unset;
    }

    .darkBox .label {
        color: #fff;
        font-weight: 600;
    }

    .darkBox .title {
        text-align: left;
        color: #fff;
        font-weight: 600;
        font-family: 'Open Sans', Arial, sans-serif;
        font-size: 1rem;
        line-height: 1.125rem;
        padding-top: 0rem;
    }

    .suggests {
        width: 100%;
        top: 3.125rem;
        overflow: auto;
        max-height: 18.75rem;
        z-index: 10;
        background-color: var(--white);
        border-top: solid #fff 0rem;
        border-bottom: solid #fff 0rem;
        opacity: 0;
        transition: 0.5s;
    }

    .suggests.open {
        border-top: solid var(--stroke) 0.125rem;
        border-bottom: solid var(--stroke) 0.125rem;
        margin-bottom: 0.625rem;
        opacity: 1;
    }

    .suggests .suggest {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        width: 100%;
        line-height: 1.875rem;
        font-size: 0.875rem;
        cursor: pointer;
        transition: 0.5s;
    }

    .suggests .suggest:hover,
    .suggests .suggest.highlighted {
        background-color: var(--primary);
        color: #fff;
    }

    /* CSS Document */

    .t800 {
        cursor: unset;
        opacity: 0.2;
        pointer-events: none;
        text-decoration: line-through;
    }

    .row {
        position: relative;
        float: left;
        width: 100%;
        clear: both;
    }

    .middle {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .center {
        text-align: center;
        width: 100%;
    }

    .space {
        margin-bottom: 1.5625rem;
    }

    .half {
        position: relative;
        float: left;
        width: 50%;
    }

    .quarter {
        position: relative;
        float: left;
        width: 25%;
    }

    .third {
        position: relative;
        float: left;
        width: 33%;
    }

    .thirtyOne3 {
        position: relative;
        float: left;
        width: 31.3%;
        margin-left: 0.48%;
    }

    .pad20 {
        padding-left: 1.25rem;
    }

    .twoThirds {
        position: relative;
        float: left;
        width: 67%;
    }

    .threeForths {
        position: relative;
        float: left;
        width: 60%;
    }

    .forth {
        position: relative;
        float: left;
        width: 25%;
    }

    .fifth {
        position: relative;
        float: left;
        width: 20%;
    }

    .grid {
        display: grid;
        position: relative;
        float: left;
        width: 100%;
    }

    .grid.two {
        grid-template-columns: auto auto;
        column-gap: 1.25rem;
    }

    .grid.three {
        grid-template-columns: auto auto auto;
        column-gap: 1.25rem;
    }

    .grid-row:after {
        content: '';
        display: table;
        clear: both;
    }

    .grid-row .col {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
    }

    .grid-row .col + .col {
        background: var(--primaryColor);
        left: auto;
        right: 0;
    }

    .logs .log-icon {
        width: 2.0625rem;
        height: 2.0625rem;
        background-image: url(/assets/svgs/Icon_Log_Logout.svg);
        background-size: contain;
        position: absolute;
        left: 0.625rem;
        top: 0.625rem;
    }

    .logs .MOP {
        background-image: url(/assets/svgs/icon.svg);
    }

    .logs .Network {
        background-image: url(/assets/svgs/AddNetworkBig.svg);
    }

    .checkText {
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: bold;
        color: var(--stroke);
    }

    .ccCardIcon {
        background-image: url(/assets/svgs/CreditCard.svg);
        width: 2.1875rem;
        height: 1.5625rem;
        background-size: contain;
        background-position: left 0.3125rem top 0.3125rem;
        background-repeat: no-repeat;
        display: inline-block;
    }

    /* Modal Styles */
    .modal {
        position: fixed;
        background-color: var(--background);
        border-radius: 0.4375rem;
        z-index: 1001;
        margin: auto;
        top: 0rem;
        left: 0rem;
        bottom: 0rem;
        right: 0rem;
        box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
        -webkit-box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
        -moz-box-shadow: 0rem 0.75rem 1.4375rem 0rem var(--shadow);
    }

    #AddModal .row.subarea {
        padding: 1.25rem;
    }

    #AddModal .title {
        padding-top: 3.125rem;
        text-align: center;
        width: 100%;
        color: var(--primaryColor);
        font-family: 'Russo One';
        text-transform: uppercase;
        position: relative;
        font-size: 0.875rem;
    }

    #AddModal .label.curved {
        color: var(--stroke);
        font-size: 0.75rem;
        text-transform: uppercase;
        position: relative;
        float: left;
        width: 100%;
        text-align: left;
        margin-bottom: 0.125rem;
        padding-left: 1.25rem;
    }

    #ShareModal {
        width: 40rem;
        height: 21.875rem;
    }

    #ConfirmModalLogout {
        width: 37.5rem;
        height: 14.0625rem;
        min-height: 14.0625rem;
    }

    #ConfirmModalHostRouter {
        width: 37.5rem;
        height: 18.75rem;
        min-height: 18.75rem;
        .icon {
            width: 8.4375rem;
        }
    }

    .confirmModal .title {
        background-color: var(--navigation);
        color: #434c5e;
        font-size: 1.875rem;
        position: relative;
        float: left;
        margin-left: 11.25rem;
        margin-top: 3.125rem;
        padding-left: 0rem;
        width: 21.875rem;
        background: none;
        line-height: 1.875rem;
        text-transform: none;
        height: auto;
        font-weight: 300;
        font-family: 'Open Sans';
    }

    .confirmModal .subtitle {
        color: var(--stroke);
        font-size: 0.875rem;
        position: relative;
        float: left;
        width: 28.125rem;
        margin-left: 6.25rem;
        margin-top: 0.8125rem;
        font-weight: 400;
        font-family: 'Open Sans';
        line-height: 1.625rem;
    }

    .confirmModal .icon {
        position: absolute;
        top: 3.75rem;
        left: 1.25rem;
        width: 9.375rem;
        background-size: 90%;
        height: 9.375rem;
        background-position: center center;
    }

    .confirmModal .icon.Logout {
        width: 9.375rem;
        height: 9.375rem;
        background-image: url(/assets/svgs/Icon_Log_Logout.svg);
        background-size: contain;
        position: absolute;
        left: 1.4375rem;
        top: 1.375rem;
    }

    .confirmModal .icon.HostEdgeRouter {
        background-image: url(/assets/svgs/hostedrouter.svg);
    }

    .confirmModal .icon.Delete {
        background-image: url(/assets/svgs/Confirm_Trash.svg);
    }

    .confirmModal .icon.DestructiveDelete {
        background-image: url(/assets/svgs/DestructiveDelete.svg);
    }

    .confirmModal .icon.BrowZer {
        background-image: url(/assets/svgs/BrowZer.svg);
    }

    .confirmModal .icon.resetMfa {
        background-image: url(/assets/svgs/ResetMFA.svg);
    }

    .confirmModal .icon.AddaNetwork {
        background-image: url(/assets/svgs/AddaNetwork.svg);
    }

    .confirmModal .icon.Reset {
        background-image: url(/assets/svgs/APIKey.svg);
    }

    .confirmModal .icon.RouterHosted {
        background-image: url(/assets/svgs/RouterHosted.svg);
    }

    .confirmModal .icon.Caution {
        background-image: url(/assets/svgs/caution.svg);
    }

    .modal .icon.AzureSites {
        background-image: url(/assets/svgs/AVWSite.svg);
    }

    .confirmModal .icon.AddRegion {
        background-image: url(/assets/svgs/ConfirmAddRegions.svg);
    }

    .confirmModal .icon.ZitiGateway {
        background-image: url(/assets/svgs/ZitiGateway.svg);
    }

    .confirmModal .icon.Timeout {
        background-image: url(/assets/svgs/Timeout.svg);
    }

    .o365Modal .icon.O365 {
        background-image: url(/assets/svgs/O365icon.svg);
        left: 3.4375rem;
        top: 2.5rem;
    }

    .icon.apikey {
        background-image: url(/assets/svgs/APIKey.svg);
        background-size: 58%;
        line-height: 4.0625rem;
        height: 4.0625rem;
        margin-left: -0.625rem;
    }

    .icon.CAs {
        background-image: url(/assets/svgs/cert-auths.svg);
        background-size: 58%;
        line-height: 4.0625rem;
        height: 4.0625rem;
        margin-left: -0.625rem;
    }

    .editRolesModal .icon.userRoles {
        background-image: url(/assets/svgs/UserRoles.svg);
        left: 3.4375rem;
        top: 2.5rem;
        height: 4.0625rem;
        width: 4.0625rem;
    }

    .buttonRow {
        margin-top: 0rem;
        width: 100%;
        position: relative;
        float: left;
        padding-bottom: 0.3125rem;
        padding-right: 0rem;
        margin-top: 1.5625rem;
        margin-bottom: 1.5625rem;
    }

    #ItemsList {
        display: inline-block;
        width: 100%;
    }

    #AddUserModal {
        width: 46.875rem;
        height: 32.8125rem;
    }

    .modal .title {
        text-overflow: ellipsis;
        overflow: visble;
        height: 4.625rem;
        line-height: 4.0625rem;
        color: var(--primaryColor);
        font-size: 1.875rem;
        padding-left: 6.25rem;
        padding-right: 1.25rem;
        padding-top: 0.625rem;
        background-position: left 1.875rem top 0.625rem;
        background-size: contain;
        width: 100%;
        font-family: 'Russo One';
        filter: brightness(var(--brightnessDark));
    }

    .modal .subtitle {
        height: 1.5rem;
        line-height: 1.1875rem;
        color: var(--tableText);
        font-size: 0.875rem;
        padding-left: 6.25rem;
        padding-right: 1.25rem;
        margin-top: -0.5rem;
        margin-left: 0.125rem;
        margin-bottom: -0.3125rem;
        width: 100%;
        font-weight: 400;
        font-family: 'Open Sans';
    }

    .modal .row {
        padding: 1.875rem;
        padding-bottom: 0rem;
        &.deleteRow {
            padding: 0;
        }
    }

    .modal.solid .button,
    .modal.solid .save {
        position: absolute;
        right: 1.875rem;
        bottom: 1.25rem;
    }

    button {
        &.confirm {
            background-color: var(--primaryColor);
        }
    }

    .location {
        font-weight: 400;
        font-family: 'Open Sans', Arial, sans-serif;
        width: 100%;
        border-color: var(--inputBorder);
        border-style: solid;
        border-width: .1rem;
        border-radius: 0.4375rem;
        padding-left: 0.625rem;
        padding-right: 0.625rem;
        outline: none;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        font-size: 0.9375rem;
        color: var(--tableText);
        -webkit-appearance: none;
        background-color: var(--background) !important;
        height: 3.125rem;
        text-align: left;
        margin-bottom: 0rem;
        line-height: 3.125rem;
    }

    .modalBg {
        background-color: var(--shadowThick);
        position: fixed;
        top: 0rem;
        width: 100%;
        height: 100%;
        z-index: 1000;
        opacity: 1;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        pointer-events: none;
    }

    .modalBg.open {
        opacity: 1;
    }

    .modal.solid {
        width: 37.5rem;
        height: 21.25rem;
        background-color: var(--background);
        border-radius: 1.5625rem;
    }

    .listBox.row .close:before,
    .modal.solid .close:before {
        content: '\e905';
    }

    .listBox.row .close,
    .modal.solid .close {
        font-family: 'icomoon' !important;
        font-style: normal;
        font-weight: 600;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: var(--icon);
        text-align: center;
        font-size: 2.1875rem;
        line-height: 2.5rem;
        position: absolute;
        right: 0.625rem;
        top: 0.625rem;
        width: 2.8125rem;
        height: 2.1875rem;
        z-index: 600;
        background-position: center center;
        background-size: 75%;
        cursor: pointer;
        transition: 0.3s;
    }

    .modal.solid .close:hover {
        filter: brightness(var(--brightnessDark));
        transition: 0.3s;
    }

    .modal.solid .close:active {
        filter: brightness(40%);
        transform: scale(0.95);
        transition: 0.3s;
    }

    .modal.solid .miniClose:before {
        content: '\e905';
    }

    .modal.solid .miniClose {
        font-family: 'icomoon' !important;
        font-style: normal;
        font-weight: 600;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: var(--icon);
        text-align: center;
        font-size: 2.1875rem;
        line-height: 2.1875rem;
        position: absolute;
        right: 0.9375rem;
        top: 0.9375rem;
        width: 2.5rem;
        height: 2.5rem;
        z-index: 600;
        background-position: center center;
        background-size: 75%;
        cursor: pointer;
        transition: 0.3s;
    }

    .modal.solid .miniClose:hover {
        filter: brightness(var(--brightnessDark));
        transition: 0.3s;
    }

    .modal.solid .miniClose:active {
        filter: brightness(40%);
        transform: scale(0.95);
        transition: 0.3s;
    }

    /* Product Styles */
    .product {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .product .title {
        font-size: 1.125rem;
        font-weight: 400;
        font-family: 'Open Sans', Arial, sans-serif;
        color: var(--stroke);
        text-align: center;
        padding-bottom: 0.625rem;
        position: relative;
        width: 100%;
        clear: both;
    }

    .product .content {
        font-size: 0.8125rem;
        color: var(--stroke);
        line-height: 1.5625rem;
        text-align: center;
        position: relative;
        width: 100%;
        clear: both;
    }

    .product .icon {
        min-height: 10.9375rem;
        max-width: none;
        background-size: contain;
        background-position: center bottom;
        width: 100%;
        position: relative;
        float: left;
        background-repeat: no-repeat;
        margin-top: 1.25rem;
    }

    .timeline.item .clock {
        position: absolute;
        right: 0.9375rem;
        font-size: 1.875rem;
        top: 1.25rem;
        line-height: 1.25rem;
        height: 1.875rem;
        width: 1.875rem;
        font-weight: 600;
    }

    .timeline.item .name {
        color: var(--tableText);
        margin-left: 0rem;
        font-size: 0.875rem;
    }

    .timeline.item {
        line-height: 3.125rem;
        font-size: 0.9375rem;
        background-color: var(--navigation);
        margin: 1.25rem;
        margin-bottom: 0.625rem;
        margin-top: 0.625rem;
        border-radius: 0.625rem;
        background-size: auto 1.4375rem;
        background-position: top 0.625rem right 0.625rem;
        padding-left: 0.625rem;
        box-shadow: 0rem 0.375rem 0.75rem 0rem var(--shadow);
    }

    .timeline.item .details {
        line-height: 1.125rem;
        font-size: 0.75rem;
        padding-bottom: 0.625rem;
        margin-top: 0.125rem;
    }

    .switchGrid {
        display: grid;
        grid-template-columns: 4.6875rem auto;
        margin-top: 0.625rem;
        line-height: 1.625rem;

        &.disabled {
            pointer-events: none !important;

            .toggle {
                pointer-events: none !important;
                color: var(--placeholder);
                background-color: var(--white);
                border: 1px solid var(--placeholder);

                .switch {
                    border: 1px solid var(--placeholder);
                }
            }
        }
    }

    .toggle {
        width: 3.875rem;
        height: 1.625rem;
        font-size: 0.875rem;
        line-height: 1.5625rem;
        font-weight: 800;
        font-family: 'Open Sans', Arial, sans-serif;
        background-color: var(--icon);
        color: var(--white);
        text-align: center;
        cursor: pointer;
        position: relative;
        float: left;
        border-radius: 0.8125rem;
    }

    .toggle.on {
        background-color: var(--green);
    }

    .toggle.on .switch {
        left: 2.4375rem;
    }

    .toggle .switch {
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 0.625rem;
        background-color: var(--white);
        position: absolute;
        left: 0.1875rem;
        top: 0.1875rem;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
    }

    .nameValueGrid {
        position: absolute;
        top: 0rem;
        grid-column-gap: 0.625rem;
        display: grid;
        grid-template-columns: auto auto;
    }

    .nameValueGrid.right {
        right: 1.25rem;
    }

    .nameValueGrid.stacked {
        grid-template-columns: 100%;
        height: 1.875rem;
        padding-top: 0.625rem;
    }

    .nameValueGrid.stacked .nameLabel {
        text-align: center;
        line-height: 1.25rem;
        height: 1.25rem;
        font-size: 1rem;
        font-weight: 600;
    }

    .nameValueGrid.stacked .nameValue {
        text-align: center;
        line-height: 0.625rem;
        height: 0.625rem;
        font-size: 0.75rem;
        font-weight: 600;
    }

    .nameLabel {
        text-align: right;
        line-height: inherit;
        height: 3.125rem;
    }

    .nameValue {
        text-align: left;
        color: var(--icon);
        line-height: inherit;
        height: 3.125rem;
    }

    .title.center {
        text-align: center;
    }

    /* Padding and Margins */

    .clear {
        clear: both;
    }

    .marginBottom {
        margin-bottom: 3.125rem;
    }

    .marginVertical {
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;
    }

    .padRight {
        padding-right: 0.625rem;
    }

    .rPad {
        padding-right: 2.1875rem;
    }

    .lPad {
        padding-left: 1.875rem;
    }

    .selectedHeight {
        height: 25rem;
    }

    .padBottom {
        padding-bottom: 4.6875rem;
    }

    .nf-pagination .ngx-pagination .current {
        background: var(--primaryColor);
        color: var(--white);
    }

    .nf-pagination .ngx-pagination li {
        border-radius: 1.875rem;
        color: var(--tableText);
    }

    .nf-pagination .ngx-pagination a,
    .ngx-pagination button {
        color: #0a0a0a;
        border-radius: 1.875rem;
        color: var(--tableText);
    }

    .noBilling {
        background-image: url(/assets/svgs/NoBillingDashboard.svg);
        margin-top: 4% !important;
    }

    .mat-mdc-tooltip {
        display: flex;
        position: relative;
        --mdc-plain-tooltip-supporting-text-font: Open sans;
        --mdc-plain-tooltip-supporting-text-size: 0.875rem;
        --mdc-plain-tooltip-supporting-text-weight: 600;
        --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
        --mdc-plain-tooltip-container-color: var(--menu);
        --mdc-plain-tooltip-supporting-text-color: white;
    }

    #OpenAlarmsArea {
        height: 23.125rem;
    }

    #OpenAlarmsArea.billing {
        height: 23.125rem;
    }

    #OpenAlarmsArea.billing .noData {
        width: 100%;
        height: 90%;
        top: 0rem;
        position: absolute;
        background-image: url(/assets/svgs/nodata.svg);
        background-position: top 5.25rem center;
        background-size: 11.25rem;
        color: var(--icon);
        opacity: 0.25;
        font-size: 1.5rem;
        text-align: center;
        padding-right: 1.875rem;
        line-height: 32.375rem;
        text-transform: uppercase;
        font-weight: 600;
        font-family: 'Open Sans';
    }

    .cardError {
        text-align: right;
        left: 0rem;
        font-size: 13x;
        position: relative;
    }

    .ccError {
        min-height: 1.125rem;
        max-height: 2.8125rem;
        display: block;
        white-space: nowrap;
        text-align: right;
        bottom: 4.375rem;
    }

    .tMenu.open {
        height: fit-content;
    }

    .mdc-radio__background {
        width: 1.875rem !important;
        height: 1.875rem !important;
        position: absolute !important;
        top: 0.3125rem;
        left: 0.3125rem;
    }

    .mat-mdc-radio-button.mat-mdc-radio-checked
        .mdc-radio__native-control:enabled
        + .mdc-radio__background
        .mdc-radio__inner-circle,
    .mat-mdc-radio-button.mat-mdc-radio-checked:hover
        .mdc-radio__native-control:enabled
        + .mdc-radio__background
        .mdc-radio__inner-circle {
        background-color: var(--primaryColor);
        --mdc-radio-selected-icon-color: var(--primaryColor);
        --mdc-radio-selected-hover-color: var(--primaryColor);
        --mdc-radio-selected-hover-icon-color: var(--primaryColor);
        transform: scale(0.7) !important;
    }

    .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__outer-circle {
        border-color: unset;
    }

    .mdc-radio__outer-circle {
        box-sizing: border-box;
        height: 1.875rem !important;
        left: 0;
        position: absolute;
        top: 0;
        transition: border-color ease 280ms;
        width: 1.875rem !important;
        border-width: 0.125rem;
        border-style: solid;
        border-radius: 50%;
        background-color: white;
        border-color: white;
        --mdc-radio-selected-hover-icon-color: white;
    }

    .mdc-radio__inner-circle {
        border-radius: 50%;
        box-sizing: border-box;
        height: 1.875rem !important;
        left: 0rem !important;
        position: absolute;
        top: 0rem !important;
        transition: transform ease 280ms, background-color ease 280ms;
        width: 1.875rem !important;
        line-height: 2.8125rem !important;
        transform: scale(0.001);
        box-shadow: 0 0.1875rem 0.5625rem 0 var(--primaryColorOpaque);
        background-color: white;
        border-color: white;
    }

    .mat-mdc-radio-button
        .mdc-radio
        .mdc-radio__native-control:enabled:not(:checked)
        + .mdc-radio__background
        .mdc-radio__outer-circle,
    .mat-mdc-radio-button
        .mdc-radio:hover
        .mdc-radio__native-control:enabled:checked
        + .mdc-radio__background
        .mdc-radio__outer-circle,
    .mat-mdc-radio-button
        .mdc-radio
        .mdc-radio__native-control:enabled:checked
        + .mdc-radio__background
        .mdc-radio__outer-circle {
        --mdc-radio-selected-icon-color: white;
        --mdc-radio-selected-hover-color: white;
        --mdc-radio-selected-hover-icon-color: white;
        --mdc-radio-unselected-hover-icon-color: white;
        border-color: white;
        background-color: white;
    }

    .mat-mdc-radio-button
        .mdc-radio
        [aria-disabled='true']
        .mdc-radio__native-control
        + .mdc-radio__background
        .mdc-radio__inner-circle,
    .mat-mdc-radio-button
        .mdc-radio
        .mdc-radio__native-control:disabled
        + .mdc-radio__background
        .mdc-radio__inner-circle {
        background-color: var(--icon);
        --mdc-radio-disabled-unselected-icon-color: var(--icon);
        --mdc-radio-disabled-unselected-icon-opacity: 1;
        box-shadow: none;
        margin-left: -0.375rem;
    }

    .mat-mdc-radio-button
        .mdc-radio
        [aria-disabled='true']
        .mdc-radio__native-control
        + .mdc-radio__background
        .mdc-radio__outer-circle,
    .mat-mdc-radio-button
        .mdc-radio
        .mdc-radio__native-control:disabled
        + .mdc-radio__background
        .mdc-radio__outer-circle {
        --mdc-radio-disabled-selected-icon-color: #fff;
        --mdc-radio-disabled-unselected-icon-color: #fff;
        --mdc-radio-disabled-selected-icon-opacity: 1;
        --mdc-radio-disabled-unselected-icon-opacity: 1;
        box-shadow: none;
        margin-left: -0.375rem;
    }

    .mat-mdc-radio-button
        .mdc-radio
        .mdc-radio__native-control:disabled:checked
        + .mdc-radio__background
        .mdc-radio__outer-circle {
        background-color: var(--icon);
        border-width: 0.25rem;
    }

    .mat-mdc-radio-button
        .mdc-radio
        .mdc-radio__native-control:disabled:checked
        + .mdc-radio__background
        .mdc-radio__inner-circle {
        border-color: var(--icon);
    }

    .mdc-radio__native-control:disabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
        border-width: 0.25rem;
    }

    #mat-radio-2 > div > div > div.mdc-radio__background > div.mdc-radio__inner-circle .node.edge-router circle {
        stroke: #ffc000 !important;
    }

    .node.endpoint circle {
        stroke: #0273fb !important;
    }

    .node.service circle {
        stroke: #08dc5a !important;
    }

    .node.app-wan circle {
        stroke: #ff0d49 !important;
    }

    .node.edge-router-policy circle {
        stroke: #ff8400 !important;
    }

    .node.service-edge-router-policy circle {
        stroke: #ff5900 !important;
    }

    .text-container {
        color: var(--tableText);
        font-size: 1.5rem;
        font-weight: bold;
        margin-left: 1.25rem;
        margin-top: 0.5rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    svg > g > g.node:first-of-type {
        &.terminate {
            cursor: pointer;

            .text-container {
                cursor: pointer;
            }

            circle {
                cursor: pointer;
            }

            &:hover {
                > rect {
                    fill: url(#NodeHoverGradient);
                }
            }
        }
    }

    .node {
        > rect {
            fill: url(#NodeGradient);
        }

        &.terminate {
            cursor: default;

            .text-container {
                cursor: default;
            }

            circle {
                cursor: default;
            }

            &:hover {
                > rect {
                    fill: url(#NodeGradient);
                }
            }
        }

        &:hover {
            > rect {
                fill: url(#NodeHoverGradient);
            }
        }
    }

    p-chips {
        &.error {
            .p-chips.p-component {
                .p-chips-multiple-container {
                    border-color: var(--red);
                    border-width: 0.125rem;
                }
            }
        }
    }

    .checkboxes.error .mat-checkbox-frame {
        border-color: var(--red);
    }

    .checkboxes.error .mat-checkbox-label {
        color: var(--red);
    }

    .stop1 {
        stop-color: var(--table);
        stop-opacity: 0.9;
    }

    .stop2 {
        stop-color: var(--table);
        stop-opacity: 0.65;
    }

    .stop3 {
        stop-color: var(--table);
        stop-opacity: 0;
    }

    .stop1a {
        stop-color: var(--background);
        stop-opacity: 1;
    }

    .stop2a {
        stop-color: var(--background);
        stop-opacity: 0.7;
    }

    .stop3a {
        stop-color: var(--background);
        stop-opacity: 0;
    }

    .code-editor-container {
        .ag-header-cell-text,
        .ag-header-cell-label {
            cursor: default !important;
        }
    }

    .ng-star-inserted {
        .p-chips-multiple-container {
            &.p-disabled {
                background-color: var(--tableText) !important;
                border: none;
                opacity: 0.8;
            }

            .p-chips-input-token {
                input:disabled {
                    background-color: var(--tableText) !important;
                    border: none;
                }
            }
        }
    }

    .forwarded-protocol.mini-pod {
        background-color: var(--tableText) !important;

        .mat-checkbox-label {
            color: var(--placeholder);
        }

        .mat-checkbox-inner-container {
            opacity: 0.7;
        }
    }

    .jsoneditor {
        border: 0.125rem solid #0472fb !important;

        border-radius: 0.4375rem;
    }

    .jsoneditor-menu {
        border-top-left-radius: 0.1875rem;

        border-top-right-radius: 0.1875rem;

        background-color: #0472fb !important;

        border-bottom: 0.125rem solid #0472fb !important;
    }

    .jsoneditor-statusbar {
        border-bottom-left-radius: 0.3125rem;

        border-bottom-right-radius: 0.3125rem;
    }

    .jsoneditor-contextmenu .jsoneditor-menu button {
        color: #ffffff !important;
    }

    div.error .jsoneditor {
        border-color: var(--red) !important;

        box-shadow: 0 0rem 0.4375rem 0 var(--redShadow);
    }

    .jsoneditor {
        border: 0.125rem solid #0472fb !important;

        border-radius: 0.4375rem;
    }

    .jsoneditor-menu {
        border-top-left-radius: 0.1875rem;

        border-top-right-radius: 0.1875rem;

        background-color: #0472fb !important;

        border-bottom: 0.125rem solid #0472fb !important;
    }

    .jsoneditor-statusbar {
        border-bottom-left-radius: 0.3125rem;

        border-bottom-right-radius: 0.3125rem;
    }

    .jsoneditor-contextmenu .jsoneditor-menu button {
        color: #ffffff !important;
    }

    div.error .jsoneditor {
        border-color: var(--red) !important;

        box-shadow: 0 0rem 0.4375rem 0 var(--redShadow);
    }

    .mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface {
        --mdc-dialog-container-color: transparent;
        box-shadow: none;
        border-width: 0;
    }

    .cardStack {
        .title {
            color: var(--tableText);
            font-size: 0.9375rem;
            text-transform: uppercase;
            padding-left: 1.125rem;
            padding-right: 0.625rem;
            background-position: top 0.5625rem right 0.5rem;
            background-size: 1.5625rem 1.5625rem;
            height: 2.9375rem;
            line-height: 2.9375rem;
            border-bottom: var(--primaryColor) solid 0.1875rem;
            font-weight: 700;
            z-index: 10;
            padding-top: 0;
            text-align: left;
            width: 100%;
            font-weight: 600;
            font-family: Open Sans, Arial, sans-serif;
            position: relative;
        }
    }
}

.cdk-overlay-container {
    z-index: 999998;

    .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: 0.5;
    }
}

.cdk-overlay-dark-backdrop {
    background-color: rgba(12, 12, 29, 0.95);
    transition: 0.5s;
}

.cdk-global-overlay-wrapper .mat-dialog-container {
    box-shadow: unset;
    border-radius: unset;
    background: unset;
}

.cdk-global-overlay-wrapper {
    backdrop-filter: none;
}

.line {
    top: 3.4375rem;
}

.bread {
    top: 1.5625rem !important;
    right: 6.25rem !important;
}

#ConfirmModal {
    .button {
        background-color: var(--red);
    }
    &.open {
        display: block;
    }
    display: none;
}

.filters .navigate.next,
.filters .navigate.prev {
    top: 0.5rem !important;
}

main {
    .ziti-page-container {
        .header {
            .action {
                top: 0.5rem !important;
            }
        }

        .data-table-container {
            height: 100%;
        }
    }

    .ag-cell {
        &.ag-cell-value {
            font-size: 0.9375rem;
            font-weight: 400;
            font-family: Open Sans;
        }
    }
}

body {
    svg {
        pointer-events: initial;
    }
}

.mat-mdc-tab-label-container {
    width: 18.75rem;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  background-color: var(--navigation) !important;
  padding: 0 !important;
  padding-left: 0.3125rem !important;
  padding-right: 0.3125rem !important;

  .select2-selection__choice {
    margin: 0.3125rem !important;
    line-height: 1.875rem !important;
    padding-left: 1.875rem !important;
    padding-right: 0.625rem !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;

    .select2-selection__choice__remove {
      left: 0.3125rem;
    }
  }
}

.header {
  #Breadcrumb1 {
    display: none;
  }
}

#AddAnyModal {
  display: none !important;
}
#WizardModal {
  display: none !important;
}

.cell-name-renderer strong {
    color: var(--tableText);
    font-size: 0.9375rem;
    font-weight: 400;
    font-family: "Open Sans";
}

.cell-menu-container .dots {
  color: var(--tableText);
  font-weight: 700;
}

.side-navigator-container {
    background-color: var(--navContainerBackground) !important;
}
